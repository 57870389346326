import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
/** @jsx jsx */
import { jsx } from 'theme-ui'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Container from '../components/Container'
import Img from 'gatsby-image'
import Flex from '../components/Flex'
import Button from '../components/Button'

const propTypes = {
  data: PropTypes.object,
}

const NotFoundPage = ({ data }) => (
  <Layout>
    <SEO title="404: Not found" />
    <Container smallplus>
      <h1>Not found or the misery of an 404</h1>
    </Container>
    <Img fluid={data.notFoundImage.childImageSharp.fluid} />
    <Flex smallplus>
      <p>Die gew&uuml;nschte URL existiert nicht ..., ok dann am besten</p>
      <p>
        <Button as="a" href={`/`} variant={`small`} sx={{ ml: [0, 2, 3, 4] }}>
          zurück nach Hause
        </Button>
      </p>
    </Flex>
  </Layout>
)

NotFoundPage.propTypes = propTypes

export default NotFoundPage

export const query = graphql`
  query {
    notFoundImage: file(relativePath: { eq: "404/daniel-jensen-UDleHDOhBZ8-unsplash.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`
