import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
/* @jsx jsx */
import { jsx } from 'theme-ui'

const propTypes = {
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullscreen: PropTypes.bool,
  large: PropTypes.bool,
  smallplus: PropTypes.bool,
  bg: PropTypes.string,
  children: PropTypes.node,
}

const defaultProps = {
  maxWidth: '960px',
  margin: '0 auto',
  padding: '3rem 5vw 1.5rem',
  bg: 'transparent',
  large: false,
  smallplus: false,
  fullscreen: false,
  children: null,
}

const Flex = ({
  children,
  maxWidth,
  height,
  margin,
  padding,
  bg,
  fullscreen,
  large,
  smallplus,
  ...props
}) => {
  const Wrapper = styled.div({})

  return (
    <Wrapper
      sx={{
        display: `flex`,
        flexWrap: `wrap`,
        maxWidth:
          (fullscreen && `container.fullscreen`) ||
          (large && `container.large`) ||
          (smallplus && `container.smallplus`) ||
          `container.default`,
        height: height,
        margin: margin,
        padding: (fullscreen && `0`) || padding,
        //pt: [3,4,5],
        bg: bg,
        zIndex: `-2`, // from Hero
        overflow: `hidden`,
      }}
      {...props}
    >
      <>{children}</>
    </Wrapper>
  )
}

Flex.propTypes = propTypes
Flex.defaultProps = defaultProps

export default Flex
